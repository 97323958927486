﻿.container {
  @include min-max;
}

.text-c {
  text-align: center;
}

.header__burger {
  display: none;
}

header {
  height: 100vh;
  min-height: 100%;
  position: relative;
  display: flex;
  background: $color-dr;
  align-items: center;
  justify-content: center;

  &.other__page {
    height: auto;
    min-height: auto;

    .header__content {

      h1 {
        font-size: 36px;
        line-height: 42px;
        margin: 40px 0;
      }
    }



    &:before {
      background: url("../img/hbg.png") 50% -40% no-repeat;
    }
  }

  &:before {
    content: '';
    background: url("../img/hbg.png") 50% 62% no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    z-index: 0;
  }

  .top__nav {
    position: absolute;
    background: $bg;
    width: 100%;
    top:0;
    z-index: 10;
    padding: 15px 0;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .logo a {
      width: 120px;
      display: block;

      img {
        width: 100%;
      }
    }
    .menu__top {

      ul {
        display: flex;

        li {
          margin-left: 30px;
          margin-bottom: 0;
          position: relative;

          .sub-menu {
            display: flex;
            flex-direction: column;
            padding-top: 31px;
            clip: rect(1px, 1px, 1px, 1px);
            opacity: 0.1;
            -webkit-transition: opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1), -webkit-transform 0.1s cubic-bezier(0.2, 0, 0.2, 1);
            transition: opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1), -webkit-transform 0.1s cubic-bezier(0.2, 0, 0.2, 1);
            transition: transform 0.1s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
            transition: transform 0.1s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1), -webkit-transform 0.1s cubic-bezier(0.2, 0, 0.2, 1);
            -webkit-transform: translateY(-10px) scaleY(0.5);
            transform: translateY(-10px) scaleY(0.5);
            -webkit-transform-origin: top;
            transform-origin: top;
            list-style: none;
            margin: 0;
            padding: 28px 0 0 0;
            position: absolute;
            top: 100%;
            left: 0;
            width: 250px;

            &:before {
              content: '';
              position: absolute;
              top: 23px;
              left: 14px;
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 5px solid $color-dr;
            }

            li {
              background: #fff;
              margin: 0;
              position: relative;

              a {
                color: $color-text;
                display: block;
                font-size: 14px;
                padding: 8px 15px;
                border-bottom: 1px solid #eaeaea;

                &:hover {
                  color: $color-dr;
                }
              }

              &:first-child {
                border-top: 3px solid $color-dr;
              }

              &:last-child a {
                border: 0;
              }
            }
          }

          &:before {
            display: none;
          }

          a {
            text-decoration: none;
            color: $linkcolor;

            &:hover {
              color: $color-dr;
            }
          }
          &:hover .sub-menu {
            clip: auto;
            opacity: 1;
            -webkit-transform: translateY(0) scaleY(1);
            transform: translateY(0) scaleY(1);
          }
        }
      }
    }

    .top__phone {
      display: flex;
      flex-direction: column;
      text-align: right;

      a {
        color: $color-dr;
        text-decoration: none;
        font-size: 20px;
        font-weight: 600;

        &:hover {
          color: $color-text;
        }
      }
    }

    &.scrolled {
      box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.65);
      -webkit-backdrop-filter: blur(55px) saturate(180%);
      backdrop-filter: blur(45px) saturate(180%);
      will-change: auto;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 9;
      padding: 0!important;
      animation: show-top 1 .3s cubic-bezier(.21,.67,.58,1);
      animation-fill-mode: forwards;

      .logo a img {
        width: 150px;
      }
    }
  }
  .header__content {
    position: relative;
    text-align: center;
    color: #fff;
    text-shadow: 0 2px 2px #273272;
    font-size: 20px;
    padding-top: 86px;

    .header__t_text {
      text-transform: uppercase;
    }

    h1 {
      font-size: 45px;
      font-weight: 400;
    }
    p {
      line-height: 30px;
    }
    img.gerb {
      margin-bottom: 30px;
      width: 170px;
    }
  }
}

.sm__title {
  color: $color-dr;
  font-weight: 600;
}

section {
  padding: 80px 0;

  h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    color: $color-dr;
    text-align: center;
    margin-bottom: 60px;
  }

  &.about {

    .container {
      display: flex;
      justify-content: space-between;
    }

    .about__left {
      width: 100%;
      padding-right: 100px;
      text-align: justify;
    }

    .about__right {
      flex: 0 0 470px;
      border-radius:15px;
      overflow: hidden;
      background: url("../img/about__r.jpg") no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      .video__link {
        position: relative;
        padding: 0 50px 0 90px;
        background: $color-dr;
        border: 3px solid #fff;
        border-radius: 50px;
        height: 66px;
        text-shadow: 0 2px 2px #273272;
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          border: 3px solid rgba(255, 255, 255, 0);
        }

        &:before {
          content: '';
          background: url("../img/play.png") no-repeat;
          width: 23px;
          height: 28px;
          position: absolute;
          top:16px;
          left: 50px;
          z-index: 1;
        }
      }
    }

    h2 {
      text-align: left;
      margin-bottom: 30px;
    }
  }
  &.specialists {
    background: $color-dr;
    color: #fff;

    h2 {
      color: #fff;
    }

    .specialist__block {
      display: flex;
      flex-wrap: wrap;

      .specialist_item {
        position: relative;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .specialist__name {
          font-size: 30px;
          line-height: 38px;
          font-weight: 600;
          margin: 20px 0;

          span.first_word {
            display: block;
          }
        }

        p {
          font-size: 18px;
          line-height: 24px;
        }
        .specialist__phone {
          font-size: 40px;
          font-weight: 400;
          margin: 40px 0;

          a {
            color: #fff;
            text-decoration: none;
          }
        }

        &:first-child:after {
          content: '';
          height: 100%;
          width: 1px;
          background: radial-gradient(#4874cd 0%, rgba(145, 152, 229, 0) 60%);
          position: absolute;
          right: 0;
          top:0;
        }

        &:first-child:before {
          content: '';
          height: 100%;
          width: 1px;
          background: radial-gradient(#263b62 0%, rgba(145, 152, 229, 0) 60%);
          position: absolute;
          right: -1px;
          top:0;
        }
      }
    }
  }

  &.services {

    .services__block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .services__item {
        width: 30%;
        background: $color-dr;
        color: #fff;
        text-align: center;
        border-radius: 15px;
        padding: 80px 15px 50px;
        position: relative;

        .services__item__title {
          font-size: 18px;
          font-weight: 600;
          margin: 50px 0 30px;
          text-transform: uppercase;
        }

        p {
          font-size: 14px;
          margin-bottom: 30px;
        }

        &:before {
          content: '';
          position: absolute;
          bottom: -6px;
          width: 92%;
          border-radius: 10px;
          left: 4%;
          background: $bg;
          z-index: -1;
          height: 150px;
          border:1px solid #c1c1c1;
          border-top: 0;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -12px;
          width: 84%;
          border-radius: 10px;
          left: 8%;
          z-index: -2;
          height: 150px;
          border:1px solid #c1c1c1;
          border-top: 0;
        }
      }
    }
  }

  &.prices {
    background: #fff;
  }

  &.articles {

    .articles__home__block {
      margin: 0 -15px;

      .slick-slide {
        height: auto;
      }

      .articles__item__b {
        margin:0 15px;
        outline: none;
      }

      .articles__item {
        background: #fff;
        border-radius: 15px;
        overflow: hidden;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;

        .articles__item__img {
          position: relative;
          height: 278px;
          overflow: hidden;

          &:before {
            content: '';
            position: absolute;
            background: rgba(56, 84, 140, 0.3);
            width: 100%;
            height: 100%;
            top:0;
            left: 0;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .articles__item__title, p {
          padding: 0 30px;
        }

        .articles__item__title {
          font-size: 18px;
          line-height: 24px;
          color: $color-dr;
          overflow: hidden;
          font-weight: 600;
          margin: 20px 0;
          height: 50px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        .btn {
          align-self: center;
          margin-top: 20px;
        }
      }
    }
    .btn__blue {
      width: 160px;
      display: flex;
      text-align: center;
      margin: 80px auto 0;
      justify-content: center;
    }
  }

  &.gallery__home {
    background: #fff;

    .gallery__home__block {
      margin:0 -15px;

      .gallery__item__b {
        margin:0 15px;
        outline: none;
      }

      .gallery__item {
        position: relative;
        border-radius: 15px;
        overflow: hidden;

        .gallery__item__img {
          width: 100%;
          height: 600px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .gallery__item__desc {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          font-size: 14px;
          padding: 80px 40px 20px;
          color: #FFFFFF;
          background: linear-gradient(0deg, #000 0%, rgba(145, 152, 229, 0) 100%);

          .gallery__item__title {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  &.partners {

    .partners__block {
      display: grid;
      grid-template-columns: repeat(auto-fill, 32%);
      grid-gap: 1rem;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;

      a {
        height: 125px;
        border-radius: 15px;
        text-align: center;
        padding: 30px 60px;
        font-weight: 500;
        color: $color-dr;
        text-decoration: none;
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        justify-content: center;
        background: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          background: $color-dr;
          color: #fff;
        }
      }
    }
  }

  &.contact {
    background: #fff;

    .contact__top {
      background: #f7f7f7;
      padding:20px 30px;
      display: flex;
      justify-content: space-between;

      .contact__top__item {
        display: flex;
        align-items: center;
        width: 33%;

        .contact__top__text {
          margin-left: 30px;
          color: #292929;

          .contact__top__title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
          }

          .contact__top__c {
            font-size: 20px;

            a {
              text-decoration: none;
              color: #292929;
            }
          }
        }
      }
    }
    .contact__center {
      padding:40px 0 30px;
      display: flex;
      justify-content: space-between;
      font-weight: 500;

      .show__btn {
        color: $color-dr;

        &:hover {
          text-decoration: none;
        }
      }

      .contact__center__item {
        display: flex;
        width: 48%;
        align-items: flex-start;

        &:first-child {
          padding-left: 30px;
        }

        .contact__center__text {
          margin-left: 30px;
          width: 100%;

          .ccontact__center__title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;

          }
        }

        .contact__center__c {

          .contact-form {
            position: relative;

            span.susses {
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #398c38;
              z-index: 100;
              border-radius: 5px;
              color: #fff;
            }

            span.error {
              font-size: 12px;
              color: #bf3d3d;
              display: block;
            }

            .input__row {
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;

              input {
                width: 48%;
              }
            }
            .ctch__btn {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .btn__bl {
                position: relative;
              }
            }
          }
        }
      }
    }

    .contact__map {
      margin-top: 30px;
    }
  }

  &.content {
    padding: 75px 0;

    .content__block {
      display: flex;
      justify-content: space-between;

      .sidebar {
        flex: 0 0 270px;
        margin-right: 30px;

        .sidebar__item {
          border-radius: 5px;
          padding:15px 15px 30px 15px;
          background: #fff;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
          margin-bottom: 30px;

          h4 {
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 20px;
            font-size: 14px;
          }

          .sidebar__list {

            .sidebar__list__item {

              &:last-child a {
                border: 0;
                margin-bottom: 0;
                padding-top: 20px;
                padding-bottom: 0;
              }

              a {
                display: flex;
                align-items: center;
                text-decoration: none;
                margin-bottom: 0;
                padding-bottom: 20px;
                border-bottom: 1px solid #e7ebf0;

                .sidebar__list__item__img {
                  width: 75px;
                  height: 100px;
                  display: block;
                  transition: all .15s ease-in-out;
                  flex: 0 0 75px;
                  background: #fff;
                  padding: 5px;
                  border: 1px solid #e7ebf0;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
                .sidebar__list__item__desc {
                  font-size: 12px;
                  line-height: 16px;
                  padding-left: 10px;
                  transition: all .15s ease-in-out;
                  color: #3c3c3c;

                  p {
                    margin: 0;
                  }

                  span {
                    color: $color-dr;
                    font-weight: 600;
                    margin-bottom: 10px;
                    display: inline-block;
                  }
                }

                &:hover .sidebar__list__item__desc {
                  color: $color-dr;
                }

                &:hover

                .sidebar__list__item__img {
                  border: 1px solid $color-dr;
                }
              }
            }

            .sidebar__list__news {

              a {
                font-size: 12px;
                display: block;
                color: #3c3c3c;
                padding: 15px 0;
                margin: 0;
                font-weight: 500;
                line-height: 15px;
                border-bottom: 1px solid #e7ebf0;

                &:hover {
                  color: $color-dr;
                  text-decoration: none;
                }
              }

              &:last-child a {
                border: 0;
                padding-bottom: 0;
              }
            }

            .btn__all {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 30px;

              a {
                font-size: 10px;
                height: 30px;
                padding: 0 20px;
              }
            }

            a.video__link {
              height: 240px;
              display: block;
              position: relative;

              &:before {
                content: '';
                position: absolute;
                left: 0;
                top:0;
                width: 100%;
                height: 100%;
                background: url("../img/vv.png") 50% 50% no-repeat;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }

      .content__body {
        width: 100%;
        overflow: hidden;

        .spec__top {
          display: flex;
          justify-content: space-between;

          .spec__desc {
            width: 100%;

            .specialist__phone {

              a {
                font-size: 24px;
                color: $color-dr;
                margin-top: 30px;
                text-decoration: none;

                &:hover {
                  color: #000;
                }
              }
            }
          }

          .spec__img {
            flex: 0 0 270px;
            height: 350px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            margin-right: 30px;

            &:before {
              content: '';
              position: absolute;
              background: rgba(56,84,140,.2);
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .spec__desc {

            h1 {
              font-size: 26px;
              line-height: 32px;
              font-weight: 700;
              color: $color-dr;
              margin-top: 0;
            }
          }
        }
        .page__cnt {
          padding: 40px 0;

          &.full_pg {
            padding: 0 0 40px 0;

            h1 {
              font-size: 26px;
              line-height: 32px;
              font-weight: 700;
              color: #38548c;
              margin-top: 0;
              margin-bottom: 30px;
            }

            h2 {
              font-size: 22px;
              font-weight: 700;
              line-height: 30px;
              text-align: left;
              margin: 20px 0;
            }
          }

          .page__cnt__block {
            font-size: 14px;
            font-weight: 500;
            background: #fff;
            padding: 35px 25px;
            margin-bottom: 30px;

            a {
              color: $color-dr;

              &:hover {
                text-decoration: none;
              }
            }

            h3 {
              font-size: 16px;
              margin:0 0 20px 0;
              font-weight: 700;
              color: $color-dr;

              a {
                color: $color-dr;
              }

              &.no-m {
                margin: 0;
              }
            }
            ul.related__news {
              display: flex;
              flex-wrap: wrap;
              padding: 0;

              li {
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: -1px;
                border: 1px solid $bg;
                transition: all .15s ease-in-out;

                &:before {
                  display: none;
                }

                &:nth-child(2n) {
                  margin-left: -1px;
                }

                a {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  padding: 10px 20px;
                  text-decoration: none;
                  color: #3c3c3c;
                  font-weight: 500;
                  font-size: 12px;

                  .related_img {
                    width: 50px;
                    height: 50px;
                    flex: 0 0 50px;
                    border-radius: 3px;
                    overflow: hidden;
                    margin-right: 15px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }

                  .related_title {
                    line-height: 18px;
                  }
                }

                &:hover {
                  border: 1px solid $color-dr;
                  background: $color-dr;

                  a {
                    color: #fff;
                  }

                }
              }
            }
          }
        }

        .certificate__slider {
          margin: 0 -15px;
          margin-bottom: 50px;

          .slick-slide {
            margin: 0 15px;
          }

          .certificate__item {
            border:1px solid #f1f1f1;
            padding: 10px;
            height: 370px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}


.cont__gr #dle-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;


  article.shortstory {
    width: 48%;
    text-align: center;
    background: #fff;
    padding:20px 40px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;

    h2 {
      font-size: 22px;
      line-height: 32px;
      margin:15px 0;
    }

    .specialist__phone {

      a {
        font-size: 24px;
        color: $color-dr;
        margin-bottom: 30px;
        display: inline-block;
        text-decoration: none;

        &:hover {
          color: #000;
        }
      }
    }

    .spec__img {
      width: 70%;
      height: 350px;
      overflow: hidden;
      position: relative;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.nospec {
      padding:0;
      border-radius: 10px;
      overflow: hidden;
      text-align: left;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 18px;
        line-height: 24px;
        color: #38548c;
        padding: 0 30px;
        overflow: hidden;
        font-weight: 600;
        margin: 20px 0;
        height: 50px;
        text-align: left;
      }

      p {
        padding: 0 30px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        height: 78px;
        overflow: hidden;
      }

      a.btn {
        align-self: center;
        margin-bottom: 20px;
      }

      .spec__img {
        width: 100%;
        height: 278px;
        overflow: hidden;
        position: relative;
        margin: 0 auto;

        a {
          display: block;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            background: rgba(56,84,140,.2);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }



      .gallery__item {
        position: relative;
        border-radius: 15px;
        overflow: hidden;

        .gallery__item__img {
          width: 100%;
          height: 420px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .gallery__item__desc {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          font-size: 14px;
          padding: 80px 15px 20px;
          color: #FFFFFF;
          background: linear-gradient(0deg, #000 0%, rgba(145, 152, 229, 0) 100%);

          p {
            padding: 0;
          }

          .gallery__item__title {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.print__share {
  display: flex;
  align-items: center;
  font-size: 18px;
  opacity: 0.5;
  transition: all .15s ease-in-out;
  margin-top: -15px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7ebf0;

  &:hover {
    opacity: 1;
  }

  i.fad {
    margin-right: 4px;
  }

  span.print__share__title {
    font-size: 12px;
    margin-right: 10px;
  }

  li:before {
    display: none;
  }
}

.fancybox-thumbs__list a::before {
  border: 3px solid $color-dr;
}

.page__title {
  font-size: 36px;
  line-height: 42px;
  margin: 40px 0;
}

footer {
  background: $color-dr;
  text-align: center;
  font-weight: 300;
  color: #fff;
  padding: 40px 0;
}

.slider__arrow {
  display: flex;
  justify-content: flex-end;
  color: $color-dr;
  font-size: 41px;
  margin-bottom: 10px;

  & > div {
    margin-left: 20px;
    cursor: pointer;
    transition: all .15s ease-in-out;

    &:hover {
      color: $btnbg;
    }
  }
}

.slick-dots {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

@media (max-width: 1200px) {
  section.services .services__block .services__item {
    width: 32%;
  }
  .cont__gr #dle-content article.shortstory {
    padding: 20px 20px;
  }
}
@media (max-width: 1200px) {
  header .top__nav .menu__top ul li {
    margin-left: 15px;
  }
  section.about .about__left {
    padding-right: 40px;
  }
  section.services .services__block .services__item .services__item__title {
    font-size: 16px;
  }
  section.partners .partners__block {
    grid-template-columns: repeat(auto-fill,48%);
  }

}

@media (max-width: 1080px) {
  header .header__content {
    padding-top: 64px;
  }
  section.about .about__left {
    text-align: left;
  }
  header .top__nav {
    padding: 10px 0;
  }
  header .top__nav.scrolled {
    padding: 10px 0!important;
  }

  header .top__nav .menu__top ul li .sub-menu {
    flex-direction: column;
    clip: inherit;
    opacity: 1;
    transition: none;
    transform: none;
    margin: 0;
    padding: 0;
    display: none;
    position: relative;
    top: auto;
    left: 0;
    width: auto;
  }
  header .top__nav .menu__top ul li .sub-menu:before {
    display: none;
  }
  header .top__nav .menu__top ul li .sub-menu li:first-child {
    border-top: 0;
  }
  header .top__nav .menu__top ul li .sub-menu li {
    border: 0;
    padding: 0;
  }

  .header .header__burger {
    display: block;
    position: absolute;
    right: 0;
    top: 0;

    button.burger {
      display: block;
      position: absolute;
      top: 32px;
      right: 42px;
      outline: none;
      background-color: transparent;
      border: none;
      appearance: none;
      z-index: 1600;
      transition: all .3s;
    }

    button.burger.burger_st {
      top: 30px !important;
    }

    button.burger span.box {
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      transition: .3s;
    }

    button.burger span.box span.bar {
      right: 0;
      width: 100%;
      height: 2px;
      background-color: $color-dr;
      position: absolute;
      transition: .5s;
      border-radius: 3px;
    }

    button.burger span.box span.bar.top {
      top: 4px;
    }

    button.burger span.box span.bar.middle {
      top: 50%;
      margin: -1px 0 0;
    }

    button.burger span.box span.bar.bottom {
      bottom: 4px;
    }

    button.burger.active span.box span.bar {
      width: 100% !important;
      background-color: #000;
    }

    button.burger.active span.box span.bar.top {
      -webkit-transform: rotate(45deg) translate(9px, 9px);
      -ms-transform: rotate(45deg) translate(9px, 9px);
      transform: rotate(45deg) translate(9px, 9px);
      top: 0;
    }

    button.burger.active span.box span.bar.middle {
      opacity: 0;
    }

    button.burger.active span.box span.bar.bottom {
      -webkit-transform: rotate(-45deg) translate(6px, -7px);
      -ms-transform: rotate(-45deg) translate(6px, -7px);
      transform: rotate(-45deg) translate(6px, -7px);
      bottom: 2px;
    }
  }
  header .top__nav .menu__top {
    position: fixed;
    top: 0;
    right: -420px;
    height: 100vh;
    background-color: #fff;
    width: 420px;
    display: block;
    z-index: 100;
    transition: 0.7s cubic-bezier(0.86, 0, 0.07, 1);
  }


  .header .top__nav .menu__top.active {
    right: 0;
    display: block;
  }
  header .top__nav .user__menu__top {
    padding-right: 70px;
    position: relative;
    z-index: 1000;
  }
  header .top__nav .logo a {
    width: 100px;
  }
  header .top__nav .menu__top ul {
    flex-direction: column;
    height: 100%;
    padding:100px 15px 0;

    li {
      font-size: 18px;
      font-weight: 400;
      padding: 20px 0;
      border-bottom: 1px solid #eaeaea;

      &:last-child {
        border: 0;
      }

      a {
        font-weight: 400;
        display: block;
      }
    }
  }
  header .top__nav .logo {
    z-index: 8000;
  }
  header .header__content {
    text-shadow: 0 1px 1px #273272;
    font-size: 16px;
  }
  header .header__content h1 {
    font-size: 35px;
  }
  header .header__content p br {
    display: none;
  }
  section.about .container {
    flex-direction: column;
  }
  section.about .about__left {
    padding-right: 0;
  }
  section.about .about__right {
    flex: 0 0 324px;
  }

  section.specialists .specialist__block .specialist_item {
    width: 100%;
  }
  section.specialists .specialist__block .specialist_item:first-child:before {
    height: 1px;
    width: 100%;
    right: 0;
    top: auto;
    bottom: 0;
  }
  section.specialists .specialist__block .specialist_item:first-child:after {
    height: 1px;
    width: 100%;
    right: 0;
    top: auto;
    bottom: -1px;
  }
  section.specialists .specialist__block .specialist_item:first-child {
    margin-bottom: 30px;
    padding-bottom: 50px;
  }
  section.services .services__block {
    flex-direction: column;
    align-items: center;
  }
  section.services .services__block .services__item {
    width: 320px;
    margin-bottom: 30px;
  }
  table.prices_tbl tbody tr td:first-child {
    width: 43%;
    flex: 0 0 43%;
  }
  section.contact .contact__center, section.contact .contact__top {
    flex-wrap: wrap;
  }
  section.contact .contact__top .contact__top__item {
    width: 100%;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e6e6e6;

    &:last-child {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  section.contact .contact__top .contact__top__item .contact__top__text .contact__top__title, section.contact .contact__center .contact__center__item .contact__center__text .contact__center__c, section.contact .contact__center .contact__center__item .contact__center__text .ccontact__center__title {
    font-size: 16px;
    line-height: 20px;
  }
  section.contact .contact__center .contact__center__item .contact__center__text .contact__center__c {
    line-height: 23px;
  }
  section.contact .contact__top .contact__top__item .contact__top__text .contact__top__c {
    font-size: 18px;
  }
  section.contact .contact__center .contact__center__item {
    width: 100%;

    &:first-child {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid #e6e6e6;
    }
  }
  section.content .content__block {
    flex-direction: column-reverse;
  }
  section.content .content__block .sidebar {
    flex: 0 0 100%;
    margin-right: 0;
  }
  header .top__nav .top__phone {
    margin-right: 50px;
    z-index: 2000;
  }
}

@media (max-width: 810px)  {
  section.content .content__block {
    margin: 0 -15px;
  }

  section.content .content__block .content__body .spec__top .spec__desc {
    padding: 0 15px;
  }

  section.content .content__block .sidebar .sidebar__item {
    border-radius: 0;
    box-shadow: none;
  }

  section h2 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
  }


  table.prices_tbl tbody tr {
    flex-direction: column;
    padding: 10px 0;
  }

  table.prices_tbl tbody tr td:first-child {
    width: 100%;
    flex: 0 0 100%;
  }
  table.prices_tbl span.price__tt, table.prices_tbl tbody tr td:last-child {
    text-align: left;
    flex: 0 0 100%;
  }
  table.prices_tbl tbody tr td:first-child {
    padding-right: 20px;
  }
  td, th {
    padding: 5px 20px;
  }
  section.content .content__block .content__body .spec__top .spec__img {
    flex: 0 0 170px;
    height: 250px;
  }
  header .top__nav .top__phone {
    line-height: 18px;
  }
  header .top__nav .top__phone a {
    font-size: 16px;
  }
  #button__top {
    width: 30px;
    height: 30px;
    bottom: 15px;
    right: 15px;
  }
  #button__top::after {
    font-size: 1em;
    line-height: 30px;
  }
}
@media (max-width: 768px)  {
  section.articles .btn__blue {
    width: 160px;
    margin: 30px auto 0;
  }
  section.articles .articles__home__block .articles__item .articles__item__img {
    height: 220px;
  }
  section.articles .articles__home__block .articles__item .articles__item__title, section.articles .articles__home__block .articles__item p {
    padding: 0 15px;
  }

  section.partners .partners__block {
    grid-template-columns: repeat(auto-fill,100%);

    a {
      height: auto;
      padding: 30px;
    }
  }
  section.contact .contact__top .contact__top__item img, section.contact .contact__center .contact__center__item img {
    width: 40px;
  }
  section.contact .contact__center {
    padding: 40px 0 15px;
  }
  section.contact .contact__top {
    padding: 20px 0;
    margin: 0 -15px;
  }
  section.contact .contact__top .contact__top__item {
    padding:0 15px 15px 15px;
  }
  section.contact .contact__top .contact__top__item .contact__top__text, section.contact .contact__center .contact__center__item:first-child .contact__center__text {
    margin-left: 15px;
  }
  section.contact .contact__center .contact__center__item .contact__center__text {
  margin-left: 0;
}
  section.contact .contact__center .contact__center__item:first-child {
    padding-left: 0;
  }
  section.contact .contact__center .contact__center__item .contact__center__text .contact__center__c {
    font-size: 14px;
  }
  section.contact .contact__center .contact__center__item .contact__center__c .contact-form .input__row {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  section.contact .contact__center .contact__center__item .contact__center__c .contact-form .input__row input {
    width: 100%;
    margin-bottom: 10px;
  }
  .cont__gr #dle-content {
    flex-direction: column;
    align-items: center;
  }

  .cont__gr #dle-content article.shortstory {
    width: 70%;
    margin-bottom: 20px;
  }
}
@media (max-width: 610px)  {
  header .top__nav .logo a {
    width: 80px;
  }

  header .header__content {
    padding-top: 55px;
  }
  header .top__nav .menu__top {
    right: -100%;
    width: 100%;
  }
  header .top__nav .menu__top ul li {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;

    a {
      font-weight: 500;
    }
  }
  .header .header__burger button.burger {
    top:27px;
  }
  section.content .content__block .content__body .spec__top .spec__desc h1 {
    font-size: 20px;
    line-height: 26px;
  }
  .page__title {
    font-size: 22px;
    line-height: 30px;
    margin: 40px 0;
  }

  .cont__gr #dle-content article.shortstory {
    width: 100%;

    &.nospec h2 {
      height: auto;
      font-size: 16px;
      line-height: 20px;
      padding: 0 15px;
    }
    &.nospec p {
      height: auto;
      padding: 0 15px;
    }

    h2 {
      height: auto;
      font-size: 16px;
      line-height: 20px;
      padding: 0 15px;
    }
    p {
      height: auto;
      padding: 0 15px;
    }
  }
  section.content .content__block .content__body .page__cnt .page__cnt__block {
    padding: 25px 15px;
  }
  section.content .content__block .content__body .page__cnt.full_pg h1, section.content .content__block .content__body .spec__top .spec__desc h1 {
    font-size: 20px;
    line-height: 26px;
  }
  section.content .content__block .content__body .page__cnt .page__cnt__block ul.related__news li {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #e7ebf0;

    &:hover {
      border: 0;
      border-bottom: 1px solid #38548c;
    }

    &:last-child {
      border-bottom: 1px solid #fff;
    }
  }
  section.content .content__block .content__body .page__cnt .page__cnt__block ul.related__news li:nth-child(2n) {
    margin-left: 0;
  }
  section.content .content__block .content__body .page__cnt .page__cnt__block ul.related__news li a {
    padding: 10px;
  }
  section.content .content__block .content__body .page__cnt.full_pg {
    padding: 0 0 10px;
  }

  .cont__gr #dle-content article.shortstory, .cont__gr #dle-content article.shortstory.nospec {
    border-radius: 0;
  }
  header .header__content img.gerb {
    margin-bottom: 20px;
    width: 120px;
  }
}
@media (max-width: 550px)  {
  body {
    font-size: 14px;
  }

  header .header__content {
    font-size: 14px;
  }

  header .header__content p {
    line-height: 22px;
  }

  header .header__content h1 {
    font-size: 26px;
    line-height: 34px;
  }
  section.about .about__right .video__link {
    padding: 0 30px 0 55px;
    border: 3px solid #fff;
    height: 50px;
    color: #fff;
    font-weight: 500;

    &:before {
      background-size: contain;
      width: 17px;
      top: 12px;
      left: 30px;
    }
  }
  section.specialists .specialist__block .specialist_item img {
    width: 60%;
  }
  section.specialists .specialist__block .specialist_item .specialist__name {
    font-size: 22px;
    line-height: 28px;
  }
  section.specialists .specialist__block .specialist_item p {
    font-size: 14px;
    line-height: 20px;
  }
  section.specialists .specialist__block .specialist_item .specialist__phone {
    font-size: 28px;
    margin:10px 0 30px 0;
  }
  .btn.big__btn, .button.big__btn {
    font-size: 14px;
    height: 40px;
    padding: 0 35px;
  }
  section {
    padding: 60px 0;
  }
  section.services .services__block .services__item .services__item__title {
    font-size: 14px;
    margin: 30px 0 10px;
  }
  section.services .services__block .services__item p {
    font-size: 12px;
    line-height: 20px;
  }
  .btn, .button {
    padding: 0 26px;
    height: 34px;
    font-size: 12px;
    font-weight: 500;
  }
  section.content .content__block .content__body .spec__top {
    flex-direction: column;
  }
  section.content .content__block .content__body .spec__top .spec__img {
    flex: 0 0 250px;
    width: 170px;
    margin: 0 auto 20px;
  }
  section.content .content__block .content__body .page__cnt .page__cnt__block h3 {
    font-size: 14px;
    line-height: 20px;
  }
  section.contact .contact__center .contact__center__item .contact__center__c .contact-form .ctch__btn {
    flex-direction: column;
    align-items: flex-start;

    .btn__bl {
      margin-top: 20px;
    }
  }
}


@media (max-width: 400px)  {
  section.services .services__block .services__item {
    width: 100%;
  }
  section.gallery__home .gallery__home__block .gallery__item .gallery__item__img {
    height: 400px;
  }
  section.gallery__home .gallery__home__block .gallery__item .gallery__item__desc {
    padding: 80px 15px 10px;
  }

  .cont__gr #dle-content article.shortstory .spec__img {
    width: 67%;
    height: 300px;
  }
  section.content {
    padding: 35px 0;
  }
}



@keyframes show-top{
  0%{
    opacity:0;
    transform:translateY(-25px);
  }
  to
  {
    opacity:1;
    transform:translateY(0);
  }
}