
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding:15px 0 15px 25px;

  li {
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: '';
      position: absolute;
      left: -25px;
      top:5px;
      width: 14px;
      height: 14px;
      display: block;
      border-radius: 50%;
      border: 2px solid $color-dr;
    }
  }
}

ol {
  padding:15px 0 15px 25px;

  li {
    position: relative;
    margin-bottom: 10px;
  }
}

img {
  max-width: 100%;
}

a {
  background-color: transparent;
  transition: all .3s ease;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $bg;
  color: $color-text;

  &.over_hide {
    overflow: hidden;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 500;

  span {
    font-size: 14px;
    display: inline-block;
    font-weight: 600;
    color: #454545;
    margin-bottom: 10px;
  }
}
th, td {
  text-align: left;
  padding:20px;
  border: 1px solid #e2e2e2;
}
tbody tr:nth-child(odd) {
  background: #f2f2f2;
}
tbody tr {
  display: flex;
  justify-content: space-between;
}
tbody tr td:first-child {
  text-align: left;
  width: 55%;
  flex: 0 0 55%;
  align-self: center;
}
tbody tr td:last-child, span.price__tt {
  text-align: right;
  color: $color-dr;
  font-size: 18px;
  font-weight: 600;
  flex: 0 0 275px;
}
#review tbody tr td:first-child, #review tbody tr td:last-child {
  text-align: left;
  padding-left: 15px;
}


tbody tr td:first-child a {
  width: 60px;
  height: 60px;
  display: block;
}

table.prices_tbl, table.prices_tbl tr, table.prices_tbl td {
  border: 0;
  font-size: 14px;
  flex: 1 1 100%;
}

table.prices_tbl tbody tr td:first-child {
  padding-right: 50px;
}

table.prices_tbl tbody tr td p {
  font-size: 13px;
  color: #454545;
}

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  /* Browsers have different default form fonts */
  font-size: 13px;
  font-weight: 400;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}

p {
  margin-bottom: 20px;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Text Inputs
-----------------------------------------------*/

//noinspection ALL,CssOverwrittenProperties
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
textarea {
  height: 30px;
  line-height: 30px;
  background: #fff;
  width: 100%;
  border-radius: 3px;
  padding: 0 10px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #d7d7d7;
  transition: all .2s ease;

  &:hover {
    border-color: $color-dr;
  }

  &:focus {
    border-color: $color-dr;
  }
}

textarea {
  min-height: 110px;
  margin-bottom: 20px;
}

.slick-slide {
  outline: none;
}

/* Checkbox + radio
-----------------------------------------------*/
.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}

.checkbox + label {
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
  margin: 5px 0;
  height: 18px;
  display: flex;
  align-items: center;

  & .count_filter {
    color: #c4c4c4;
  }
}

.checkbox + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background: #fff url("../img/check.png") 50% 50% no-repeat;
  border: 1px solid #F7F7F7;
  transition: .2s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.checkbox:hover + label:before {
  border-color: var(--red);
}

.checkbox:checked + label:before {
  border-color: var(--red);
  background: var(--red) url("../img/check.png") 50% 50% no-repeat;
}

.radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px;
}

.radio + label {
  position: relative;
  padding: 0 0 0 26px;
  cursor: pointer;
  min-height: 22px;
  line-height: 22px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.radio + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transition: .2s;
  width: 18px;
  height: 18px;
  border: 2px solid #F7F7F7;
  border-radius: 50%;
  background: #FFF;
}

.radio:checked + label:before {
  border-color: var(--red);
  background: var(--red);
}

.radio:hover + label:before {
  border-color: var(--red);
}

.radio + label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  opacity: 0;
  transition: .2s;
}

.radio:checked + label:after {
  opacity: 1;
}

button, a, input[type=reset], input[type=button], input[type=submit], input[type=checkbox], input[type=radio], select {
  transition: all 0.15s ease-in-out;
}
.button, .btn {
  text-transform: uppercase;
  @include btn-gradient;
  z-index: 2;

  &:hover {
    @include btn-gradient-hover;
  }

  &.big__btn {
    font-size: 16px;
    height: 50px;
    padding: 0 40px;
  }

  &.btn__white {
    color: #fff;
    border:2px solid #fff;

    &:hover {
      background: #fff;
      color: $color-dr;
    }
  }

  &.btn__blue {
    color: #fff;
    border:2px solid $color-dr;
    background: $color-dr;

    &:hover {
      background: #fff;
      color: $color-dr;
    }
  }
}

h1 {
  font-size: 40px;
  line-height: 46px;
  font-weight: 700;
}

h2 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

#button__top {
  display: inline-block;
  background-color: #FF9800;
  background-color: rgba(0, 0, 0, 0.5);
  color: #000;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 3px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s,
  opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#button__top::after {
  content: "\f077";
  font-family: "Font Awesome 5 Pro";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  line-height: 40px;
  color: #fff;
}
#button__top:hover {
  cursor: pointer;
  background-color: $color-dr;
}
#button__top:active {
  background-color: $color-dr;
}
#button__top.show {
  opacity: 1;
  visibility: visible;
}
