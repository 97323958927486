.hide {
  display: none!important;
}
.submit__generated {
  display: inline-block;

  span {
    display: inline-block;
    width: 35px;
    height: 35px;
    vertical-align: center;
    line-height: 35px;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,0.9);
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
  }

  &.valid:after,
  &.unvalid:after {
    font-family: "Font Awesome 5 Pro";
    font-size: 18px;
    margin-left: 10px;
    font-weight: 600;
  }
  &.valid {
    &:after {
      content: "\f00c";
      color: #2ecc71;
    }
    .submit__input {
      border: 1px solid #2ecc71;
      color: #2ecc71 !important;
    }
  }
  &.unvalid {
    &:after {
      content: "\f00d";
      color: #e74c3c;
    }
    .submit__input {
      border: 1px solid #e74c3c;
      color: #e74c3c;
    }
  }

  .submit__input {
    position: relative;
    outline: 0;
    height: 35px;
    width: 45px;
    border-radius: 4px;
    border: 1px solid #42A0DD;
    color: #42A0DD;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    top: -4px;
  }
}




i.fa-sync-alt {
  margin: 4px 0 0px 5px;
  padding: 5px;
  font-size: 18px;
  color: rgba(0,0,0,0.2);
  cursor: pointer;
  transform-origin: center center;
  transition: transform 0.2s ease-out, color 0.2s ease-out;
  &:hover {
    color: rgba(0,0,0,0.4);
    transform: rotate(180deg);
  }
}

span.submit__error,
span.submit__error--empty {
  color: #e74c3c;
  position: absolute;
  margin-top: 0px;
  margin-left: 100px;
}





.submit {
  display: block;

  &.enter-press,
  &.overlay {
    margin: 0;
    box-shadow: none;
  }
}


.submit__overlay {
  height: 50px;
  width: 100%;
  background-color: rgba(255,255,255,.8);
  position: absolute;
  left: 0;
  margin-top: 0;
  margin-left: 0;
  bottom: 0;
  z-index: 10;
}








.low-opa { opacity: 0.4; }
.fadeOut {
  opacity: 0;
  transform: translateY(10px);
}
.fadeIn {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.form-fields,
.form-success {
  transition: all 0.2s ease-out;
}
.form-success {
  opacity: 0;
  transform: translateY(-10px);
  margin-top: 20px;
}