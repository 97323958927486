@mixin clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}

@mixin min-max() {
  min-width: 320px;
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto;
}

@mixin btn-gradient() {
  color: $color-dr;
  text-decoration: none;
  padding: 0 30px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 50px;
  border:2px solid $color-dr;
  position: relative;
}

@mixin btn-gradient-hover() {
  background: $color-dr;
  color: #fff;
}

@mixin adaptive-video() {
  position: relative;
  padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

@mixin visually-hidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0,0,0,0);
  overflow: hidden;
}